


export const layouts = {
'backend/App': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/App.vue'),
'frontend/App': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/App.vue'),
'backend/components/AcfunInfoTitleLine': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/AcfunInfoTitleLine.vue'),
'backend/components/AnimeRecordContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/AnimeRecordContent.vue'),
'backend/components/AnimeTitleLine': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/AnimeTitleLine.vue'),
'backend/components/AppNavbar': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/AppNavbar.vue'),
'backend/components/BangSearchLinks': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/BangSearchLinks.vue'),
'backend/components/BangumiInfoTitleLine': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/BangumiInfoTitleLine.vue'),
'backend/components/BilibiliInfoTitleLine': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/BilibiliInfoTitleLine.vue'),
'backend/components/CategoryIcon': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/CategoryIcon.vue'),
'backend/components/DoubanInfoTitleLine': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/DoubanInfoTitleLine.vue'),
'backend/components/EigaInfoTitleLine': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/EigaInfoTitleLine.vue'),
'backend/components/FeverCell': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/FeverCell.vue'),
'backend/components/GameRecordContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/GameRecordContent.vue'),
'backend/components/GameTitleLine': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/GameTitleLine.vue'),
'backend/components/InfoButton': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/InfoButton.vue'),
'backend/components/IqiyiInfoTitleLine': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/IqiyiInfoTitleLine.vue'),
'backend/components/LinkableFilter': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/LinkableFilter.vue'),
'backend/components/ModeFilter': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/ModeFilter.vue'),
'backend/components/PackFilter': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/PackFilter.vue'),
'backend/components/PageHeader': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/PageHeader.vue'),
'backend/components/PageTitle': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/PageTitle.vue'),
'backend/components/PaginationBar': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/PaginationBar.vue'),
'backend/components/PptvInfoTitleLine': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/PptvInfoTitleLine.vue'),
'backend/components/QqInfoTitleLine': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/QqInfoTitleLine.vue'),
'backend/components/RLink': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/RLink.vue'),
'backend/components/ReasonList': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/ReasonList.vue'),
'backend/components/RouterSortLink': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/RouterSortLink.vue'),
'backend/components/SectionHeader': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/SectionHeader.vue'),
'backend/components/SortLink': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/SortLink.vue'),
'backend/components/StateBadge': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/StateBadge.vue'),
'backend/components/StateFilter': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/StateFilter.vue'),
'backend/components/SyoboiInfoTitleLine': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/SyoboiInfoTitleLine.vue'),
'backend/components/TitledbInfoTitleLine': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/TitledbInfoTitleLine.vue'),
'backend/components/TypeFilter': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/TypeFilter.vue'),
'backend/components/YoukuInfoTitleLine': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/YoukuInfoTitleLine.vue'),
'backend/layouts/default': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/layouts/default.vue'),
'backend/pages/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/index.vue'),
'frontend/components/AppNavbar': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/AppNavbar.vue'),
'frontend/components/PageBody': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/PageBody.vue'),
'frontend/components/PageHeader': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/PageHeader.vue'),
'frontend/components/PageTitle': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/PageTitle.vue'),
'frontend/components/RLink': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/RLink.vue'),
'frontend/components/SectionHeader': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/SectionHeader.vue'),
'frontend/layouts/default': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/layouts/default.vue'),
'frontend/pages/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/index.vue'),
'frontend/pages/sign_in': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/sign_in.vue'),
'backend/components/controls/InputDate': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/InputDate.vue'),
'backend/components/controls/InputDatetime': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/InputDatetime.vue'),
'backend/components/controls/InputFile': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/InputFile.vue'),
'backend/components/controls/InputNumber': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/InputNumber.vue'),
'backend/components/controls/InputPassword': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/InputPassword.vue'),
'backend/components/controls/InputPlain': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/InputPlain.vue'),
'backend/components/controls/InputText': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/InputText.vue'),
'backend/components/controls/RadioInline': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/RadioInline.vue'),
'backend/components/controls/Select': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/Select.vue'),
'backend/components/controls/Selectpicker': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/Selectpicker.vue'),
'backend/components/controls/Textarea': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/Textarea.vue'),
'backend/pages/acfun_infos/Fields': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/acfun_infos/Fields.vue'),
'backend/pages/acfun_infos/[id].edit': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/acfun_infos/[id].edit.vue'),
'backend/pages/acfun_infos/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/acfun_infos/[id].vue'),
'backend/pages/acfun_infos/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/acfun_infos/index.vue'),
'backend/pages/acfun_infos/new': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/acfun_infos/new.vue'),
'backend/pages/animes/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/animes/[id].vue'),
'backend/pages/animes/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/animes/index.vue'),
'backend/pages/bangumi_infos/Fields': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bangumi_infos/Fields.vue'),
'backend/pages/bangumi_infos/[id].edit': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bangumi_infos/[id].edit.vue'),
'backend/pages/bangumi_infos/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bangumi_infos/[id].vue'),
'backend/pages/bangumi_infos/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bangumi_infos/index.vue'),
'backend/pages/bangumi_infos/new': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bangumi_infos/new.vue'),
'backend/pages/bilibili_infos/Fields': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bilibili_infos/Fields.vue'),
'backend/pages/bilibili_infos/[id].edit': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bilibili_infos/[id].edit.vue'),
'backend/pages/bilibili_infos/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bilibili_infos/[id].vue'),
'backend/pages/bilibili_infos/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bilibili_infos/index.vue'),
'backend/pages/bilibili_infos/new': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bilibili_infos/new.vue'),
'backend/pages/douban_infos/Fields': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/douban_infos/Fields.vue'),
'backend/pages/douban_infos/[id].edit': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/douban_infos/[id].edit.vue'),
'backend/pages/douban_infos/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/douban_infos/[id].vue'),
'backend/pages/douban_infos/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/douban_infos/index.vue'),
'backend/pages/douban_infos/new': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/douban_infos/new.vue'),
'backend/pages/eiga_infos/Fields': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/eiga_infos/Fields.vue'),
'backend/pages/eiga_infos/[id].edit': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/eiga_infos/[id].edit.vue'),
'backend/pages/eiga_infos/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/eiga_infos/[id].vue'),
'backend/pages/eiga_infos/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/eiga_infos/index.vue'),
'backend/pages/games/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/games/[id].vue'),
'backend/pages/games/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/games/index.vue'),
'backend/pages/iqiyi_infos/Fields': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/iqiyi_infos/Fields.vue'),
'backend/pages/iqiyi_infos/[id].edit': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/iqiyi_infos/[id].edit.vue'),
'backend/pages/iqiyi_infos/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/iqiyi_infos/[id].vue'),
'backend/pages/iqiyi_infos/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/iqiyi_infos/index.vue'),
'backend/pages/iqiyi_infos/new': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/iqiyi_infos/new.vue'),
'backend/pages/override_infos/Fields': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/override_infos/Fields.vue'),
'backend/pages/override_infos/[id].edit': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/override_infos/[id].edit.vue'),
'backend/pages/override_infos/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/override_infos/index.vue'),
'backend/pages/override_infos/new': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/override_infos/new.vue'),
'backend/pages/qq_infos/Fields': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/qq_infos/Fields.vue'),
'backend/pages/qq_infos/[id].edit': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/qq_infos/[id].edit.vue'),
'backend/pages/qq_infos/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/qq_infos/[id].vue'),
'backend/pages/qq_infos/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/qq_infos/index.vue'),
'backend/pages/qq_infos/new': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/qq_infos/new.vue'),
'backend/pages/syoboi_infos/Fields': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/syoboi_infos/Fields.vue'),
'backend/pages/syoboi_infos/[id].edit': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/syoboi_infos/[id].edit.vue'),
'backend/pages/syoboi_infos/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/syoboi_infos/[id].vue'),
'backend/pages/syoboi_infos/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/syoboi_infos/index.vue'),
'backend/pages/syoboi_infos/new': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/syoboi_infos/new.vue'),
'backend/pages/pptv_infos/Fields': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/pptv_infos/Fields.vue'),
'backend/pages/pptv_infos/[id].edit': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/pptv_infos/[id].edit.vue'),
'backend/pages/pptv_infos/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/pptv_infos/[id].vue'),
'backend/pages/pptv_infos/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/pptv_infos/index.vue'),
'backend/pages/pptv_infos/new': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/pptv_infos/new.vue'),
'backend/pages/youku_infos/Fields': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/youku_infos/Fields.vue'),
'backend/pages/youku_infos/[id].edit': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/youku_infos/[id].edit.vue'),
'backend/pages/youku_infos/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/youku_infos/[id].vue'),
'backend/pages/youku_infos/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/youku_infos/index.vue'),
'backend/pages/youku_infos/new': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/youku_infos/new.vue'),
'backend/pages/titledb_infos/Fields': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/titledb_infos/Fields.vue'),
'backend/pages/titledb_infos/[id].edit': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/titledb_infos/[id].edit.vue'),
'backend/pages/titledb_infos/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/titledb_infos/[id].vue'),
'backend/pages/titledb_infos/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/titledb_infos/index.vue'),
'backend/pages/titledb_infos/new': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/titledb_infos/new.vue'),
'frontend/components/controls/InputNumber': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/controls/InputNumber.vue'),
'frontend/components/controls/InputPassword': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/controls/InputPassword.vue'),
'frontend/components/controls/InputText': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/controls/InputText.vue'),
'frontend/components/controls/Select': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/controls/Select.vue'),
'frontend/components/controls/Textarea': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/controls/Textarea.vue'),
'frontend/pages/animes/AnimeCategoryIcon': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/AnimeCategoryIcon.vue'),
'frontend/pages/animes/BadgeBangumi': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/BadgeBangumi.vue'),
'frontend/pages/animes/BadgeBilibili': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/BadgeBilibili.vue'),
'frontend/pages/animes/BadgeDouban': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/BadgeDouban.vue'),
'frontend/pages/animes/CollectionLabel': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/CollectionLabel.vue'),
'frontend/pages/animes/DropdownContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/DropdownContent.vue'),
'frontend/pages/animes/DropdownMenu': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/DropdownMenu.vue'),
'frontend/pages/animes/EntityCell': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/EntityCell.vue'),
'frontend/pages/animes/FavouriteDropdown': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/FavouriteDropdown.vue'),
'frontend/pages/animes/SiteDmhy': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/SiteDmhy.vue'),
'frontend/pages/animes/SiteInfo': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/SiteInfo.vue'),
'frontend/pages/animes/SiteMikan': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/SiteMikan.vue'),
'frontend/pages/animes/Table': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/Table.vue'),
'frontend/pages/animes/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/[id].vue'),
'frontend/pages/animes/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/index.vue'),
'frontend/pages/animes/search': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/search.vue'),
'frontend/pages/boards/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/boards/[id].vue'),
'frontend/pages/collections/DistributionNewDialog': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/collections/DistributionNewDialog.vue'),
'frontend/pages/collections/DoubanCollectionPage': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/collections/DoubanCollectionPage.vue'),
'frontend/pages/collections/DoubanConnectCard': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/collections/DoubanConnectCard.vue'),
'frontend/pages/collections/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/collections/[id].vue'),
'frontend/pages/collections/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/collections/index.vue'),
'frontend/pages/distributions/[token]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/distributions/[token].vue'),
'frontend/pages/games/BadgeBangumi': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/games/BadgeBangumi.vue'),
'frontend/pages/games/BadgeDouban': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/games/BadgeDouban.vue'),
'frontend/pages/games/DropdownContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/games/DropdownContent.vue'),
'frontend/pages/games/GameCategoryIcon': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/games/GameCategoryIcon.vue'),
'frontend/pages/games/SiteMzplays': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/games/SiteMzplays.vue'),
'frontend/pages/games/SiteNintendo': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/games/SiteNintendo.vue'),
'frontend/pages/games/Table': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/games/Table.vue'),
'frontend/pages/games/[id]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/games/[id].vue'),
'frontend/pages/games/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/games/index.vue'),
'frontend/pages/games/search': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/games/search.vue'),
'vendor/ui/alert/Alert': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/alert/Alert.vue'),
'vendor/ui/alert/AlertDescription': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/alert/AlertDescription.vue'),
'vendor/ui/alert/AlertTitle': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/alert/AlertTitle.vue'),
'vendor/ui/alert-dialog/AlertDialog': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/alert-dialog/AlertDialog.vue'),
'vendor/ui/alert-dialog/AlertDialogAction': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/alert-dialog/AlertDialogAction.vue'),
'vendor/ui/alert-dialog/AlertDialogCancel': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/alert-dialog/AlertDialogCancel.vue'),
'vendor/ui/alert-dialog/AlertDialogContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/alert-dialog/AlertDialogContent.vue'),
'vendor/ui/alert-dialog/AlertDialogDescription': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/alert-dialog/AlertDialogDescription.vue'),
'vendor/ui/alert-dialog/AlertDialogFooter': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/alert-dialog/AlertDialogFooter.vue'),
'vendor/ui/alert-dialog/AlertDialogHeader': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/alert-dialog/AlertDialogHeader.vue'),
'vendor/ui/alert-dialog/AlertDialogTitle': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/alert-dialog/AlertDialogTitle.vue'),
'vendor/ui/alert-dialog/AlertDialogTrigger': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/alert-dialog/AlertDialogTrigger.vue'),
'vendor/ui/badge/Badge': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/badge/Badge.vue'),
'vendor/ui/button/Button': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/button/Button.vue'),
'vendor/ui/button/ButtonGroup': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/button/ButtonGroup.vue'),
'vendor/ui/card/Card': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/card/Card.vue'),
'vendor/ui/card/CardContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/card/CardContent.vue'),
'vendor/ui/card/CardDescription': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/card/CardDescription.vue'),
'vendor/ui/card/CardFooter': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/card/CardFooter.vue'),
'vendor/ui/card/CardHeader': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/card/CardHeader.vue'),
'vendor/ui/card/CardTitle': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/card/CardTitle.vue'),
'vendor/ui/card/CardTopState': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/card/CardTopState.vue'),
'vendor/ui/checkbox/Checkbox': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/checkbox/Checkbox.vue'),
'vendor/ui/checkbox/InputCheckbox': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/checkbox/InputCheckbox.vue'),
'vendor/ui/container/Container': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/container/Container.vue'),
'vendor/ui/dialog/Dialog': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dialog/Dialog.vue'),
'vendor/ui/dialog/DialogClose': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dialog/DialogClose.vue'),
'vendor/ui/dialog/DialogContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dialog/DialogContent.vue'),
'vendor/ui/dialog/DialogDescription': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dialog/DialogDescription.vue'),
'vendor/ui/dialog/DialogFooter': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dialog/DialogFooter.vue'),
'vendor/ui/dialog/DialogHeader': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dialog/DialogHeader.vue'),
'vendor/ui/dialog/DialogScrollContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dialog/DialogScrollContent.vue'),
'vendor/ui/dialog/DialogTitle': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dialog/DialogTitle.vue'),
'vendor/ui/dialog/DialogTrigger': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dialog/DialogTrigger.vue'),
'vendor/ui/dropdown-menu/DropdownMenu': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenu.vue'),
'vendor/ui/dropdown-menu/DropdownMenuCheckboxItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenuCheckboxItem.vue'),
'vendor/ui/dropdown-menu/DropdownMenuContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenuContent.vue'),
'vendor/ui/dropdown-menu/DropdownMenuGroup': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenuGroup.vue'),
'vendor/ui/dropdown-menu/DropdownMenuItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenuItem.vue'),
'vendor/ui/dropdown-menu/DropdownMenuLabel': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenuLabel.vue'),
'vendor/ui/dropdown-menu/DropdownMenuRadioGroup': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenuRadioGroup.vue'),
'vendor/ui/dropdown-menu/DropdownMenuRadioItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenuRadioItem.vue'),
'vendor/ui/dropdown-menu/DropdownMenuSeparator': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenuSeparator.vue'),
'vendor/ui/dropdown-menu/DropdownMenuShortcut': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenuShortcut.vue'),
'vendor/ui/dropdown-menu/DropdownMenuSub': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenuSub.vue'),
'vendor/ui/dropdown-menu/DropdownMenuSubContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenuSubContent.vue'),
'vendor/ui/dropdown-menu/DropdownMenuSubTrigger': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenuSubTrigger.vue'),
'vendor/ui/dropdown-menu/DropdownMenuTrigger': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/dropdown-menu/DropdownMenuTrigger.vue'),
'vendor/ui/nav/Nav': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/nav/Nav.vue'),
'vendor/ui/nav/NavItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/nav/NavItem.vue'),
'vendor/ui/nav/NavList': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/nav/NavList.vue'),
'vendor/ui/pagination/PaginationEllipsis': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/pagination/PaginationEllipsis.vue'),
'vendor/ui/pagination/PaginationFirst': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/pagination/PaginationFirst.vue'),
'vendor/ui/pagination/PaginationLast': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/pagination/PaginationLast.vue'),
'vendor/ui/pagination/PaginationNext': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/pagination/PaginationNext.vue'),
'vendor/ui/pagination/PaginationPrev': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/pagination/PaginationPrev.vue'),
'vendor/ui/progress/Progress': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/progress/Progress.vue'),
'vendor/ui/radio-group/InputRadio': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/radio-group/InputRadio.vue'),
'vendor/ui/radio-group/RadioGroup': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/radio-group/RadioGroup.vue'),
'vendor/ui/radio-group/RadioGroupItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/radio-group/RadioGroupItem.vue'),
'vendor/ui/select/Select': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/select/Select.vue'),
'vendor/ui/select/SelectContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/select/SelectContent.vue'),
'vendor/ui/select/SelectGroup': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/select/SelectGroup.vue'),
'vendor/ui/select/SelectItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/select/SelectItem.vue'),
'vendor/ui/select/SelectItemText': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/select/SelectItemText.vue'),
'vendor/ui/select/SelectLabel': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/select/SelectLabel.vue'),
'vendor/ui/select/SelectScrollDownButton': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/select/SelectScrollDownButton.vue'),
'vendor/ui/select/SelectScrollUpButton': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/select/SelectScrollUpButton.vue'),
'vendor/ui/select/SelectSeparator': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/select/SelectSeparator.vue'),
'vendor/ui/select/SelectTrigger': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/select/SelectTrigger.vue'),
'vendor/ui/select/SelectValue': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/select/SelectValue.vue'),
'vendor/ui/simple_form/Form': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/simple_form/Form.vue'),
'vendor/ui/simple_form/FormGroup': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/simple_form/FormGroup.vue'),
'vendor/ui/simple_form/PresenterConfigProvider': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/simple_form/PresenterConfigProvider.vue'),
'vendor/ui/table/Table': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/table/Table.vue'),
'vendor/ui/table/TableBody': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/table/TableBody.vue'),
'vendor/ui/table/TableCaption': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/table/TableCaption.vue'),
'vendor/ui/table/TableCell': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/table/TableCell.vue'),
'vendor/ui/table/TableEmpty': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/table/TableEmpty.vue'),
'vendor/ui/table/TableFooter': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/table/TableFooter.vue'),
'vendor/ui/table/TableHead': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/table/TableHead.vue'),
'vendor/ui/table/TableHeader': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/table/TableHeader.vue'),
'vendor/ui/table/TableRow': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/table/TableRow.vue'),
'vendor/ui/tabs/Tabs': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/tabs/Tabs.vue'),
'vendor/ui/tabs/TabsContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/tabs/TabsContent.vue'),
'vendor/ui/tabs/TabsList': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/tabs/TabsList.vue'),
'vendor/ui/tabs/TabsTrigger': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/tabs/TabsTrigger.vue'),
'vendor/ui/tooltip/Tooltip': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/tooltip/Tooltip.vue'),
'vendor/ui/tooltip/TooltipContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/tooltip/TooltipContent.vue'),
'vendor/ui/tooltip/TooltipProvider': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/tooltip/TooltipProvider.vue'),
'vendor/ui/tooltip/TooltipTrigger': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/tooltip/TooltipTrigger.vue'),
'vendor/ui/well/Well': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/vendor/ui/well/Well.vue'),
'backend/components/controls/selectdrop/SelectdropItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/selectdrop/SelectdropItem.vue'),
'backend/components/controls/selectdrop/SelectdropMenuEntry': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/selectdrop/SelectdropMenuEntry.vue'),
'backend/components/controls/selectdrop/SelectdropMenuGroup': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/selectdrop/SelectdropMenuGroup.vue'),
'backend/components/controls/selectdrop/SelectdropMenuItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/selectdrop/SelectdropMenuItem.vue'),
'backend/components/controls/selectdrop/SelectdropMenuText': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/selectdrop/SelectdropMenuText.vue'),
'backend/components/controls/selectdrop/SelectdropRoot': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/components/controls/selectdrop/SelectdropRoot.vue'),
'backend/pages/animes/bang_lines/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/animes/bang_lines/index.vue'),
'backend/pages/games/bang_lines/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/games/bang_lines/index.vue'),
'backend/pages/animes/issues/IssueSummaryCell': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/animes/issues/IssueSummaryCell.vue'),
'backend/pages/animes/issues/[code]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/animes/issues/[code].vue'),
'backend/pages/animes/issues/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/animes/issues/index.vue'),
'backend/pages/games/issues/IssueSummaryCell': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/games/issues/IssueSummaryCell.vue'),
'backend/pages/games/issues/[code]': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/games/issues/[code].vue'),
'backend/pages/games/issues/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/games/issues/index.vue'),
'frontend/components/controls/selectdrop/SelectdropItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/controls/selectdrop/SelectdropItem.vue'),
'frontend/components/controls/selectdrop/SelectdropMenuEntry': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/controls/selectdrop/SelectdropMenuEntry.vue'),
'frontend/components/controls/selectdrop/SelectdropMenuGroup': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/controls/selectdrop/SelectdropMenuGroup.vue'),
'frontend/components/controls/selectdrop/SelectdropMenuItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/controls/selectdrop/SelectdropMenuItem.vue'),
'frontend/components/controls/selectdrop/SelectdropMenuText': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/controls/selectdrop/SelectdropMenuText.vue'),
'frontend/components/controls/selectdrop/SelectdropRoot': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/components/controls/selectdrop/SelectdropRoot.vue'),
}
export const createGetRoutes = (router, withLayout = false) => {
  const routes = router.getRoutes()
  if (withLayout) {
      return routes
  }
  return () => routes.filter(route => !route.meta.isLayout)
}

export function setupLayouts(routes) {
  function deepSetupLayout(routes, top = true) {
    return routes.map(route => {
      if (route.children?.length > 0) {
        route.children = deepSetupLayout(route.children, false)
      }
      
      if (top) {
        // unplugin-vue-router adds a top-level route to the routing group, which we should skip.
        const skipLayout = !route.component && route.children?.find(r => (r.path === '' || r.path === '/') && r.meta?.isLayout)  

        if (skipLayout) {
          return route
        }

        if (route.meta?.layout !== false) {
          return { 
            path: route.path,
            component: layouts[route.meta?.layout || 'default'],
            children: route.path === '/' ? [route] : [{...route, path: ''}],
            meta: {
              isLayout: true
            }
          }
        }
      }

      if (route.meta?.layout) {
        return { 
          path: route.path,
          component: layouts[route.meta?.layout],
          children: [ {...route, path: ''} ],
          meta: {
            isLayout: true
          }
        }
      }

      return route
    })
  }

    return deepSetupLayout(routes)

}
